import React from "react"
//import { Link } from "gatsby"
//import { HiArrowNarrowLeft} from "react-icons/hi";

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackLink from "../components/backlink"
import { CgScreenShot } from 'react-icons/cg';
import { FaDrupal } from 'react-icons/fa';
import { SiGooglesearchconsole } from 'react-icons/si';
import { FaMusic } from 'react-icons/fa';


const Services = () => (
  <Layout>
    <SEO title="Services" />
    <BackLink/>
    <h1>Services</h1>
    <div id ="services-section">
      <h2><CgScreenShot style ={{marginTop:3}}/> Responsive Web Design</h2>
      <p>Mobile web browsing has surpassed desktop usage. Google now ranks pages based on mobile-first. Are you being penalized by Google for slow loading pages? Are your customers reaching your landing pages and bouncing to your competitors instead?</p>

      <p>We build mobile-first, responsive websites that look great on any device. Our designs start with mobile in mind first because we want your users to have the best possible experience. A good browsing experience is one that helps to amplify the clarity of your message and deliver to your user the products and services they are seeking. </p>
      
      <h2> <FaDrupal /> Full-Stack Drupal Development</h2>
      <p>We love Drupal! We offer a full range of Drupal consultation and development services including, site migrations, theme development, and custom module development.</p>
      
      <h2><SiGooglesearchconsole /> Search Engine Optimization </h2>
      <p>Search engine optimization(SEO) shouldn't be an afterthought. If you are putting in the time and effort to create a website, chances are you want someone to see that website. You won’t find any risky hacks or “blackhat” techniques here. We employ industry standard best practices to help our clients’ sites shine. We will help you develop and implement the long-term strategies you need to make sure your site is reaching its full organic potential.</p>
      
      {/* <h2>Digital Advertising </h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
      */}
      <h2> <FaMusic /> Music Creation &amp; Licensing </h2>
      <p>Looking for the perfect song to use for your next creative project? We are song writing and recreation services as well as provide a catalog of music that is available for licencing. </p>
    </div>
    <BackLink/>
  </Layout>
)

export default Services
